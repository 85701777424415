import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static values = {
    hovering: Boolean
  }

  hover(event) {
    this.hoveringValue = event.type == "mouseenter" ? true : false
  }

  hoveringValueChanged() {
    this.element.classList.toggle("--hovering", this.hoveringValue)
  }
}

import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex"

export default class extends Controller {

  connect () {
    StimulusReflex.register(this)
  }

  beforeReflex(event) {
    console.log("before")
    if (!this.hasSpinnerTarget) {
      this.element.appendChild(this.spinner)
    }
  }

  afterReflex(event) {
    console.log("after")
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.parentNode.removeChild(this.spinnerTarget)
    }
  }

  get spinner() {
    const element = document.createElement("div")
    element.setAttribute("data-body-target", "spinner")
    element.classList.add("spinner")
    return element
  }
}

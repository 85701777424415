import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static targets = ["header"]

  static values = {
    collapsed: Boolean
  }

  connect() {
    this.collapsedValue = this.hasCollapsedValue ? this.collapsedValue : true
  }

  toggle() {
    this.collapsedValue = !this.collapsedValue
  }
}

import { Controller } from "stimulus"
import { postFormDataAsJson } from "./body_controller.js"

export default class extends Controller {

  connect() {

    this.submitHandler = (event) => {
      this.submit(event)
    }

    const forms = document.querySelectorAll("form")

    if (forms.length > 0) {
      this.element.addEventListener("submit", this.submitHandler)
    }
  }

  disconnect() {
    this.element.removeEventListener("submit", this.submitHandler)
  }

  async submit(event) {
    event.preventDefault()
    const form = event.target.closest("form")
    
    const url = form.action
    const formData = new FormData(form)

    try {
      const response = await postFormDataAsJson(url, formData)
      this.remove()
    } catch(error) {
      console.log(error)
    }
  }

  async refresh() {
    const url = "/cookies"
    
    try {
      const html = await this.getBannerHtml()
      this.element.outerHTML = html
      return true
    } catch(error) {
      console.log(error)
    }
  }

  remove() {
    this.element.parentNode.removeChild(this.element)
  }

  async getBannerHtml() {
    const url = "/cookies"
    const response = await fetch(url)
    
    if(!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    const json = await response.json()
    return json.html
  }
}

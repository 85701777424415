import { Controller } from "stimulus"
import tippy, { followCursor } from "tippy.js"
import "tippy.js/dist/tippy.css"

export default class extends Controller {

  static targets = ["content"]

  connect() {
    if (this.hasContentTarget) {
      this.contentTarget.style.display = "block"
      const content = this.contentTarget.outerHTML
      this.contentTarget.style.display = "none"

      this.tippy = tippy(this.element, {
        content: content,
        allowHTML: true,
        appendTo: "parent",
        followCursor: true,
        placement: "bottom-start",
        animation: false,
        theme: "sgp",
        plugins: [followCursor]
      })
    }

  }
}

import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static values = {
    expanded: Boolean
  }

  static classes = ["expanded"]

  connect() {
    this.expandedValue = this.expandedValue

    this.documentCallback = (event) => {
      if (this.expandedValue && !this.element.contains(event.target)) {
        event.preventDefault()
        this.toggle(event)
      } else if (this.expandedValue) {
        const tag = event.target.tagName.toLowerCase()

        if (tag == "a") {
          this.toggle(event)
        }
      }
    }

    document.addEventListener("click", this.documentCallback)
  }

  disconnect() {
    document.removeEventListener("click", this.documentCallback)
  }

  toggle(event) {
    event.stopPropagation()
    this.expandedValue = !this.expandedValue
  }

  expandedValueChanged(bool) {
    this.element.classList.toggle(this.expandedClass, bool)
  }
}

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log("connect body controller", this.element)

    // document click handler for links' behaviour
    this.clickHandler = (event) => {
      const element = event.target
      const tagname = element.tagName.toLowerCase()

      if (tagname === "a" &&
          (location.hostname === element.hostname || !element.hostname.length)) {

        const anchor = element.href.split("#").pop()
        const target = document.getElementById(element.href.split("#").pop())

        if (anchor && target) {
          event.preventDefault()
          this.scrollTo(target, `#${anchor}`)
        }

      } else if (tagname === "a" && location.hostname !== element.hostname) {
        event.preventDefault()
        window.open(element.href, "_blank")
      }
    }

    this.element.addEventListener("click", this.clickHandler)
  }

  disconnect() {
    this.element.removeEventListener("click", this.clickHandler)
  }

  scrollTo(target, url = false) {
    window.setTimeout(() => {
      const position = target.offsetTop
      const header = document.querySelector(".header")
      const offset = header ? 
        parseInt(window.getComputedStyle(header).getPropertyValue("padding-top")) : 0

      window.scrollTo({
        top: position - 2 * offset,
        behavior: "smooth"
      })

      if (url) {
        history.replaceState(null, null, url)
      }
    }, 50)
  }
}

export async function postFormDataAsJson(url, formData) {
  console.log(url, formData)
  const entries = Object.fromEntries(formData.entries())
  const payload = JSON.stringify(entries)
  console.log(entries, payload)
  
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
    body: payload
  })

  if(!response.ok) {
    const message = await response.text()
    throw new Error(message)
  }

  return response
}

import ApplicationController from "./application_controller"
import Sortable from "sortablejs"
import Strings from "@supercharge/strings"

export default class extends ApplicationController {

  static targets = ["list", "pull", "put"]

  connect() {
    super.connect()
    
    this.groupId = Strings.random()
    this.sortables = []

    this.listTargets.forEach(elem => {
      const sortable = new Sortable(elem, {
        group: {
          name: this.groupId,
          pull: "clone",
          put: elem.getAttribute("data-put") == "true" ? true : false
        },
        sort: elem.getAttribute("data-sort") == "true" ? true : false
      })

      this.sortables.push(sortable)
    })
  }
}

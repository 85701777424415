import { Controller } from "stimulus"
import ConsentBannerController from "./consent_banner_controller.js"

export default class extends Controller {

  static targets = ["banner"]

  async showBanner(event) {
    event.preventDefault()

    if (!this.hasBannerTarget) {
      const consentBannerController = new ConsentBannerController()

      try {
        const html = await consentBannerController.getBannerHtml()
        this.element.insertAdjacentHTML("beforeend", html)
      } catch(error) {
        console.log(error)
      }
    }
  }
}

import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  connect() {
    super.connect()
  }

  assign() {
    this.stimulate("Admin::UploadReflex#assign")
  }

  submit(event) {
    event.preventDefault()
    this.stimulate("Admin::UploadReflex#update")
  }
}

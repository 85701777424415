import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static targets = ["archive", "form"]

  connect() {
    // connect to register reflex
    super.connect()

    // submit event handler (bubbles from child forms)
    this.submitHandler = (event) => {
      event.preventDefault()
      this.submit(event)
    }

    this.element.addEventListener("queryFormSubmit", this.submitHandler)
  }

  disconnect() {
    this.element.removeEventListener("queryFormSubmit", this.submitHandler)
  }

  // Gather and return data of all formTargets.
  collectedFormData() {
    const formDatas = new Array()

    this.formTargets.forEach(elem => {
      const data = new FormData(elem)
      formDatas.push(data)
    })

    const formData = new FormData()

    formDatas.forEach(item => {
      for (const pair of item.entries()) {
        const [name, value] = pair
        formData.append(name, value)
      }
    })

    return formData
  }

  // Gather all query's forms' data in one form element and stimulate reflex
  // passing this one form element. StimulusReflex will pass the params of the
  // passed form element automatically.
  submit(event) {
    
    // clear search if submit was triggered by filter form
    if (event && event.target.getAttribute("data-archive-form-type-value") == "filter") {
      this.element.querySelectorAll("input[type='search']").forEach(elem => {
        elem.value = ""
      })
    }

    const formData = this.collectedFormData()

    const form = document.createElement("form")
    for (let pair of formData.entries()) {
      const [name, value] = pair
      const input = document.createElement("input")
      input.type = "hidden"
      input.name = name
      input.value = value
      form.append(input)
    }
    
    this.element.appendChild(form)
    this.stimulate("ProgramPointsReflex#archive", form)
  }

  // clear all forms' values
  clearAll(event) {
    this.formTargets.forEach(form => {
      form.querySelectorAll("input").forEach(element => {
        element.value = ""
      })
    })

    this.submit(null)
  }

  paginate(event) {
    event.preventDefault()

    const element = event.currentTarget
    const url = element.href
    const csrfToken = document.querySelector("meta[name='csrf-token']").content
    const page = element.getAttribute("data-page") 
    const formData = this.collectedFormData()

    if (page) {
      formData.append("archive_page", page)
    }

    element.innerHTML = ""
    element.classList.add("--loading")

    fetch(url, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "X-CSRF-Token": csrfToken
      },
      body: formData
    }).then(response => {
      return response.json()
    }).then(json => {
      element.insertAdjacentHTML("afterend", json.html)
      element.parentNode.removeChild(element)
    })
 }

  beforeArchive(element) {
    this.archiveTarget.classList.add("--loading")
  }
}

import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  connect() {
    super.connect()
  }

  // toggle archive
  archive() {
    const id = "uploads_archive"
    const archive = this.element.querySelector(`#${id}`)

    if (!archive) {
      this.element.insertAdjacentHTML("beforeend", `<div id="${id}"></div>`)
      this.stimulate("Admin::UploadReflex#archive")
    } else {
      archive.parentNode.removeChild(archive)
    }
  }
}

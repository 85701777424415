import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static values = {
    type: String
  }

  connect() {
    this.clickHandler = (event) => {
      const tag = event.target.tagName.toLowerCase()
      const type = event.target.type

      if (tag == "input" &&
          (type == "checkbox" ||
           type == "radio")) {

        this.submit(event)
      }
    }

    this.submitHandler = (event) => {
      event.preventDefault()
      this.submit(event)
    }

    this.element.addEventListener("click", this.clickHandler)
    this.element.addEventListener("submit", this.submitHandler)

    const submits = this.element.querySelectorAll("input[type='submit']")

    submits.forEach(input => {
      input.parentNode.removeChild(input)
    })
  }

  disconnect() {
    this.element.removeEventListener("click", this.clickHandler)
    this.element.removeEventListener("submit", this.submitHandler)
  }

  submit(event) {
    const submitEvent = new CustomEvent("queryFormSubmit", { bubbles: true })
    this.element.dispatchEvent(submitEvent)
  }
}

import ApplicationController from "./application_controller"

export default class extends ApplicationController {

  static values = {
    method: String
  }

  connect() {
    this.methodValue = this.hasMethodValue ? this.methodValue : "replace"
  }

  paginate(event) {
    event.preventDefault()

    const element = event.currentTarget
    const url = element.href ? element.href : elemen.getAttribute("data-url")
    const csrfToken = document.querySelector("meta[name='csrf-token']").content

    this.element.classList.add("--loading")

    fetch(url, {
      method: "GET",
      headers: {
        "Accept": "application/json"
      }
    }).then(response => {
      return response.json()
    }).then(json => {

      if (this.methodValue == "replace") {
        this.element.insertAdjacentHTML("afterend", json.html)
        this.element.parentNode.removeChild(this.element)
      }
    })
  }
}

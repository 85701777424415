import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    currentSlideIndex: Number,
    currentSlide: String,
    length: String
  }

  initialize() {
    let slides = this.element.children
    slides = Array.from(slides) 

    if (slides.length > 1) { 
      console.log("is greater than 1", this.element)
      slides.forEach(elem => {
        elem.classList.add("slide")
      })

      this.clickHandler = (event) => {
        const image = event.target.closest("img")

        if (image) {
          this.next()
        }
      }

      this.element.addEventListener("click", this.clickHandler)

      this.currentSlideIndexValue = this.currentSlideIndexValue  
      this.lengthValue = slides.length
    }
  }

  disconnect() {
    this.element.removeEventListener("click", this.clickHandler)
  }

  currentSlideIndexValueChanged(value) {
    const activeClass = "slide--active"
    let slides = this.element.children
    slides = Array.from(slides)
    let slide

    slides.forEach(elem => {
      elem.classList.remove(activeClass)
    })

    this.currentSlideValue = value + 1
    this.lengthValue = slides.length

    if (slides.length > 1) {
      slide = slides[value]
      slide.classList.add(activeClass) 
      slide.setAttribute("data-current-slide-value", this.currentSlideValue)
      slide.setAttribute("data-length-value", this.lengthValue)
    }
  }

  next(index = false) {
    const slides = this.element.children
    let nextSlideIndex = index

    if (nextSlideIndex === false) {
      nextSlideIndex = this.currentSlideIndexValue + 1
    }

    this.currentSlideIndexValue = nextSlideIndex >= slides.length ? 0 : nextSlideIndex
  }
}

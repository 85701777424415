import { Controller } from "stimulus"
import Tagify from "@yaireo/tagify"

export default class extends Controller {

  static values = {
    url: String,
    enforceWhitelist: Boolean
  }

  connect() {
    let list = []

    this.fetchList()
      .then(response => { list = response })
      .catch(error => { console.log(error) })
      .finally(() => {
        console.log("list:", list)
        this.tagify = new Tagify(this.element, {
          whitelist: list,
          enforceWhitelist: this.enforceWhitelistValue,
          originalInputValueFormat: valuesArr => {
            return valuesArr.map(item => item.value).join(", ")
          }
        })

        this.tagify.on("add remove", event => { this.syncInput() })
      })
  }

  async fetchList() {
    if (this.urlValue) {
      const response = await fetch(this.urlValue)
      console.log(response)

      if (response.ok) {
        const json = await response.json()

        return json.map(object => {
          return object.value
        })
      } else {
        throw Error(response.url, response.statusText)
      }
    } else {
      return []
    }
  }

  syncInput() {
    const value = this.tagify.value.map(object => { return object.value })
    this.element.value = value.join(", ")
    console.log(this.element.value)
  }
}
